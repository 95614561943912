const $ = require('jquery');

$(document).ready(function() {
  let hamburger = $(".hamburger");
  hamburger.click(function(){
    let navigation = $('header nav.navigation');
    $(this).toggleClass("is-active");
    $('body').toggleClass("menu-open");
    hamburger.not(this).toggleClass("is-active");
    navigation.height($(window).height());
    if(hamburger.hasClass('is-active')) {
      stopBodyScrolling(true);
    } else {
      stopBodyScrolling(false);
    }
  });
});

let freezeScroll = function(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

function stopBodyScrolling (bool) {
  let fixedUnderlay = document.getElementById('layout-content');
  if (bool === true) {
    fixedUnderlay.addEventListener("touchmove", freezeScroll, false);
  } else {
    fixedUnderlay.removeEventListener("touchmove", freezeScroll, false);
  }
}
